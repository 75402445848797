import React from 'react'
import SEO from '../components/seo/seo'
import Disclaimer from '../components/disclaimer/disclaimer'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const RealEstateTitleLawPage = () => (
  <>
    <SEO 
      title="Services" 
      description="Real estate title lawyer Jim Feleen provides legal real estate transaction services for Sullivan County in NH. Call today 603-504-6507"
      keywords={['lawyer', 'law office', 'attorney', 'James Feleen', 'Jim Feleen', 'Claremont', 'NH', 'New Hampshire', 'Claremont NH', 'real estate', 'title company', 'seller', 'buyer', 'lender', 'deed', 'closing']} />
    <Banner />
    <div className="body-wrapper">
      <h2>Real Estate Title Law</h2>
      <h4>A local title company serving Claremont NH and Sullivan County.</h4>
      <p>     
        Buying a house is one of the biggest transactions of your life. Jim Feleen, a lawyer who provides real estate title transaction services, can guide you through a successful closing.
      </p>
      <p>
        Whether you are purchasing your first home, refinancing, or engaging in a commercial real estate transaction, our law office is ready to provide any legal services the real estate transaction may require. We created our own title company, Pleasant Street Title & Closing, LLC, to ensure efficient and timely production at affordable rates.
      </p>
      <p>
        Our real estate transaction legal services may involve deeds, mortgages, closings, titles or purchase and sales agreements. We work with sellers, buyers and lenders in the following ways:
      </p>
      <p>
        <strong>FOR SELLERS: </strong>Negotiate and review sales contract, deed preparation, review closing documents, powers of attorney, attend closing.
      </p>
      <p>
        <strong>FOR BUYERS: </strong>Draft sales contract, title search/examination, review loan documents, owners title insurance, coordinate and conduct closing.
      </p>
      <p>
        <strong>FOR LENDERS: </strong>Draft or review any necessary documents, title search, lenders title insurance, closings, witness closings, disbursements, recording.
      </p>
    </div>
    <Disclaimer />
  </>
)

const Banner = props => (
  <StaticQuery
    query={query}
    render={data => (
      <div className="body-wrapper">
        <Img alt="House with the lights on" className="hero-image" fluid={data.file.childImageSharp.fluid}/>
      </div>
    )}
  />
);

const query = graphql`
  query {
    file(relativePath: { eq: "house-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default RealEstateTitleLawPage
